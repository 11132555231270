import { IConcept, IQuestionProperty, TMaps } from './queryCreation.interface';

export const SUB_QUERY = 'subQuery';
export const SELF_JOIN_RELATION_NAME = '##selfJoin##';
export const SELF_JOIN_LABEL = 'SelfJoin';

export const MAPS_INITIAL_STATE: TMaps = {
  queryMap: {},
  conceptInstanceIdMap: {},
  hasError: false,
};

export const NEW_PROPERTY: IQuestionProperty = {
  windowFunctions: [],
  aggregates: [],
  label: '',
  propTypeUri: '',
  uri: '',
  filters: [],
  hidden: false,
  optional: false,
  outputCategory: null,
  propTypeLabel: null,
  round: null,
  subQueryId: null,
  truncateTo: null,
};

export const NEW_CONCEPT: IConcept = {
  properties: [],
  relations: {},
  aggregates: [],
  inheritedType: null,
  label: '',
  optional: false,
  outputCategory: null,
  selectId: false,
  uri: '',
  color: '',
  subQueryId: null,
  subQueryDefinition: {},
};
