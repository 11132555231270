import { IConcept } from '../constants/queryCreation.interface';

/**
 * If the subQueryId is defined & != null, the concept is a subQuery
 * @param concept IConcept
 * @returns boolean
 */
export const getIsSubQuestion = (concept: IConcept): boolean => {
  return Boolean(concept?.subQueryId) && concept?.subQueryId !== null;
};
