import { vidrisContrastColor } from '../contrastColors';

export const vidrisDefault: string[] = [
  '#440C53',
  '#482677',
  '#404788',
  '#34638D',
  '#228A8D',
  '#54C667',
  '#B8DE2A',
  vidrisContrastColor,
];

export const vidrisDefaultMixed: string[] = [
  vidrisDefault[0],
  vidrisDefault[2],
  vidrisDefault[4],
  vidrisDefault[6],
  vidrisDefault[1],
  vidrisDefault[3],
  vidrisDefault[5],
  vidrisDefault[7],
];
