import { addToEndOfArr, updateObjArrayAtIndex } from '@redux/utils';
import { mappingFunctionDef } from 'Modules/Studio/Mappings/redux/initialState';
import { functionValue } from 'utils/Studio/SelectOptions';

// used in query mapping functions
export const getFunctionColumn = (functionRef, columnName, index, functionKey = 'function') => {
  const columnCopy = functionRef.column;
  let updatedObj = { columnName, values: [], index };
  let updatedCol;
  if (!columnCopy[index]) {
    updatedCol = addToEndOfArr([updatedObj], columnCopy);
  } else {
    updatedCol = updateObjArrayAtIndex(updatedObj, {}, columnCopy, index);
  }

  return {
    [functionKey]: {
      ...functionRef,
      column: updatedCol,
    },
  };
};

export const getMappingFunctionValue = (
  functionRef,
  name,
  value,
  index,
  functionKey = 'function'
) => {
  // 1) update the values array
  const valuesCopy = functionRef.column?.[index]?.values || [];
  const indexOfValueToUpdate = valuesCopy.findIndex((item) => item.name === name);

  const updatedObj = { name, value, index };
  let updatedValues;
  if (indexOfValueToUpdate === -1) {
    updatedValues = addToEndOfArr([updatedObj], valuesCopy);
  } else {
    updatedValues = updateObjArrayAtIndex(updatedObj, {}, valuesCopy, indexOfValueToUpdate);
  }

  // 2) update the column array with updated values array
  let columnCopy = functionRef.column;
  const updatedColWithUpdatedVal = updateObjArrayAtIndex(
    { values: updatedValues },
    columnCopy[index],
    columnCopy,
    index
  );

  return {
    [functionKey]: {
      ...functionRef,
      column: updatedColWithUpdatedVal,
    },
  };
};

export const getOverlapsMapByTableName = (overlaps) => {
  const overlapsMapByTableName = overlaps.reduce((acc, curr) => {
    const uniqueId = `${curr.dataSourceId}.${curr.tableName}`;
    return {
      ...acc,
      [uniqueId]: {
        overlapColumnNames:
          acc[uniqueId] && acc[uniqueId].overlapColumnNames
            ? [
                ...acc[uniqueId].overlapColumnNames,
                {
                  dataSourceId: curr.dataSourceId,
                  overlapColumnName: curr.idColumnName,
                  tableName: curr.tableName,
                },
              ]
            : [
                {
                  dataSourceId: curr.dataSourceId,
                  overlapColumnName: curr.idColumnName,
                  tableName: curr.tableName,
                },
              ],
        ...curr,
      },
    };
  }, {});

  return overlapsMapByTableName;
};

export const getMatchingOverlap = (dataSetMap, conceptIdOverlaps, selectedMapping) => {
  if (!dataSetMap || !conceptIdOverlaps || !selectedMapping) {
    return [];
  }
  const selectedDataSet = dataSetMap[selectedMapping.dataSetId];
  if (!selectedDataSet) {
    return [];
  }
  const datasetID = `${selectedDataSet.dataSourceId}.${selectedDataSet.tableName}`;
  return conceptIdOverlaps.filter((id) => {
    const overlapId = `${id.dataSourceId}.${id.tableName}`;
    return overlapId === datasetID;
  });
};

/**
 * getFormDefaultsFromOverlaps - sets the selectedMapping defaults for the Concept ID tab
 * - mappingIdTypeId
 * - idColumnName
 * - idFunction,
 * @param {array} overlaps
 * @param {object} dataSetMap
 * @param {object} selectedMapping
 * @returns object
 */
export const getFormDefaultsFromOverlaps = (overlaps, dataSetMap, selectedMapping) => {
  const functionType = 'idFunction';
  let idFunction = mappingFunctionDef;

  // the two state variables being set that will set the form defaults
  let idColumnName = '';
  let mappingIdTypeId = 0;

  // set custom identifier & default settings here
  const matchingOverlap = getMatchingOverlap(dataSetMap, overlaps, selectedMapping);

  // if an overlap exists for this concept & dataset
  if (matchingOverlap.length > 0) {
    if (matchingOverlap.length > 1) {
      console.warn(
        "There are more than 1 matching overlaps; it's possible the UI will not show the expected form defaults.  Default is set to matchingOverlap[0]",
        matchingOverlap
      );
    }
    // if it's of type function, pre-populate the function
    // check to ensure it was not set to null; this happens when the user removes existing mappings for this overlap.  The BE will respond with idFunction: null and cause the app to in the isSubmitDisabled function where there's a check for selectedMapping.idFunction.column.length === 0
    if (matchingOverlap[0].idColumnType === 2 && matchingOverlap[0][functionType] !== null) {
      mappingIdTypeId = 2;
      // set local mappingDef object for the function type; this will update the UI form fields for the function that was previously set
      idFunction = matchingOverlap[0][functionType];
    } else if (matchingOverlap[0].idColumnType === 2 && matchingOverlap[0][functionType] === null) {
      // set to empty string so the user doesnt see ##function_null## which is what comes back from the BE in this error scenario
      idColumnName = '';
    } else {
      // else, set the idColumnNameInput
      idColumnName = matchingOverlap[0].idColumnName;
    }
  }

  return {
    mappingIdTypeId,
    idColumnName,
    idFunction,
    matchingOverlap: matchingOverlap?.[0] || {},
  };
};

/**
 * getLookupDefaultValuesForNewMappings - sets defaults to be used by the selectedMapping redux state variable, (in the Lookups form) for new mappings
 * @param {array} lookups
 * @returns object
 */
export const getLookupDefaultValuesForNewMappings = (lookups = []) => {
  let overlapChecked = lookups.length > 0;
  let overlapDefaultValues = {};

  if (lookups.length > 0) {
    overlapDefaultValues = {
      mappingOverlapIdTypeId: lookups[0].hasOwnProperty('mappingOverlapIdTypeId')
        ? lookups[0]?.mappingOverlapIdTypeId
        : null,
      overlapIdFunction: lookups[0].overlapIdFunction || mappingFunctionDef,
      overlapColumnName: lookups[0].lookupColumnName || null,
      overlapDatasourceId: lookups[0].dataSourceId || null,
      overlapTableName: lookups[0].lookupTableName || null,
    };
  }

  return {
    overlapDefaultValues,
    overlapChecked,
  };
};

export const getOverlapsIdentifierOptions = (overlapsMapByTablename) => {
  const overlapsArray = Object.keys(overlapsMapByTablename);

  let overlapColumnNameOptions = overlapsArray.reduce(
    (overlapsArrAcc, overlapUniqueId) => [
      ...overlapsArrAcc,
      ...overlapsMapByTablename[overlapUniqueId].columnNames.reduce((acc, columnName) => {
        const dataSourceId = Number.parseInt(overlapUniqueId);
        const idColumnName =
          overlapsMapByTablename[overlapUniqueId].idColumnName.indexOf(functionValue) > -1
            ? 'Function'
            : overlapsMapByTablename[overlapUniqueId].idColumnName;

        // prevent adding the idColumnName that is already being used
        if (idColumnName !== columnName) {
          return [
            ...acc,
            {
              columnName,
              dataSourceId,
              dataSourceName: overlapsMapByTablename[overlapUniqueId].dataSourceName,
              idColumnName,
              tableName: overlapsMapByTablename[overlapUniqueId].tableName,
            },
          ];
        } else {
          return acc;
        }
      }, []),
    ],
    []
  );

  if (overlapColumnNameOptions.length === 0) {
    overlapColumnNameOptions = [
      { value: null, label: 'There are no lookups available', isDisabled: true },
    ];
  }

  return overlapColumnNameOptions;
};
