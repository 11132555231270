import { magmaContrastColor } from '../contrastColors';

export const magmaDefault: string[] = [
  '#003F5D',
  '#665191',
  '#A05095',
  '#D45087',
  '#F95D6A',
  '#F95D6A',
  '#F9BA41',
  magmaContrastColor,
];

export const magmaDefaultMixed: string[] = [
  magmaDefault[0],
  magmaDefault[2],
  magmaDefault[4],
  magmaDefault[6],
  magmaDefault[1],
  magmaDefault[3],
  magmaDefault[5],
  magmaDefault[7],
];
