export const USED = 'used';

export const DATA_SOURCE_KEYS = {
  [USED]: 'In use',
  unUsed: 'Unused',
};

export const mockDataSource = {
  dataSourceType: 6,
  name: '',
  id: 0,
  userName: '',
  password: '',
  connectionUrl: '',
  props: {},
};

export const addDataSourcePermissions = ['datasources', 'datasource', ['c', 'u', 'd']];

export const sampleDataSourceList = {
  used: [
    {
      dataSourceType: 6,
      name: 'Water Contracts',
      id: 272,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water Events',
      id: 273,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water Incidents',
      id: 274,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water Parts',
      id: 276,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water WO',
      id: 277,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
  ],
  unUsed: [
    {
      dataSourceType: 6,
      name: 'Water Events',
      id: 273,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water Incidents',
      id: 274,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water Parts',
      id: 276,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
    {
      dataSourceType: 6,
      name: 'Water WO',
      id: 277,
      userName: '',
      password: '',
      connectionUrl: '',
      props: {},
    },
  ],
};
