import {
  IConcept,
  IQuestionProperty,
  IQuestionRelationProps,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';
import { IOutputItem } from '../constants/subQuestions.interface';
import { RELATION_PROPS, PROPERTY_PROPS } from '../constants/outputItems';

export const getProp = (
  prop: IQuestionProperty,
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string,
  color: string,
  isAggregate: boolean = false
): IOutputItem => ({
  // optional props that would apply if this was a relation output
  ...RELATION_PROPS,
  ...prop,
  conceptId,
  conceptName: concept?.outputCategory || concept.label,
  isExisting,
  subQuestionUri,
  color,
  isAggregate,
  conceptUri: concept.uri,
});

export const getRelation = (
  rel: IQuestionRelationProps,
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string,
  color: string
): IOutputItem => {
  return {
    // optional props that would apply if this was a property output
    ...PROPERTY_PROPS,
    ...rel,
    isRelation: true,
    conceptId,
    conceptName: concept?.outputCategory || concept.label,
    isExisting,
    subQuestionUri,
    color,
    isAggregate: false,
    conceptUri: concept.uri,
  };
};
