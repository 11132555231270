export const RELATION_PROPS = {
  isRelation: false,
  selfJoin: false,
  relationInstances: [],
  relationTypeLabel: '',
  relationTypeUri: '',
};

export const PROPERTY_PROPS = {
  windowFunctions: [],
  selfJoin: false,
  aggregates: [],
  filters: [],
  hidden: false,
  optional: false,
  outputCategory: null,
  propTypeLabel: null,
  propTypeUri: '',
  round: null,
  truncateTo: null,
};

export const CALCULATION_URI = 'Calculation';
export const CALCULATION_CONCEPT_LABEL = 'Calculation';
