import { pastelContrastColor } from '../contrastColors';

export const pastelDefault: string[] = [
  '#FD7F6F',
  '#7EB0D5',
  '#B2E061',
  '#BD7EBE',
  '#FFB55A',
  '#FFEE65',
  '#BEB9DB',
  '#FDCCE5',
  pastelContrastColor,
];

export const pastelDefaultMixed: string[] = [
  pastelDefault[0],
  pastelDefault[2],
  pastelDefault[4],
  pastelDefault[6],
  pastelDefault[8],
  pastelDefault[1],
  pastelDefault[3],
  pastelDefault[5],
  pastelDefault[7],
];
