/**
 * Filter parameters for data sources
 */
interface FilterParams {
  datasources: { [key: string]: any[] };
  searchTerm?: string;
  filterTypeId?: number;
}

/**
 * Applies filtering by search term and type to data sources
 * @param params Object containing filter parameters
 * @returns Filtered datasources object
 */
export const applyFilters = ({
  datasources,
  searchTerm = '',
  filterTypeId = 0,
}: FilterParams): { [key: string]: any[] } => {
  // If both filters are empty, return the original data
  if (searchTerm === '' && filterTypeId === 0) {
    return datasources;
  }

  let filteredDatasources = datasources;

  // Filter by type
  if (filterTypeId !== 0) {
    filteredDatasources = Object.keys(filteredDatasources).reduce((acc, type) => {
      return {
        ...acc,
        [type]: filteredDatasources[type].filter(
          (item: any) => item.dataSourceType === filterTypeId
        ),
      };
    }, {});
  }

  // Filter by search term
  if (searchTerm !== '') {
    filteredDatasources = Object.keys(filteredDatasources).reduce((acc, type) => {
      return {
        ...acc,
        [type]: filteredDatasources[type].filter(
          (item: any) => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        ),
      };
    }, {});
  }

  return filteredDatasources;
};
