import { TNodesMap } from '../constants/queryCreation.interface';
import { hanaDefault } from '@Apps/components/visualizations/helpers/colorSchemes/default';

type TQuestionProps = {
  uri: string;
  nodesMap: TNodesMap;
};

type TSubQueryProps = {
  index: number;
  isSubQuestion: boolean;
};

const SUB_QUERY_PROPS = { index: 0, isSubQuestion: false };

/**
 * Get the domain color string
 * @param questionProps TQuestionProps
 * @returns string
 */
const getDomainColorForQuery = (questionProps: TQuestionProps): string => {
  const { uri, nodesMap } = questionProps;
  try {
    const color = nodesMap?.[uri]?.domainColor || ''; //default to empty string; graph will render w/black nodes

    return color;
  } catch (e) {
    return '';
  }
};

/**
 * Assigns a color to the subQuery concept
 * @param subQueryProps TSubQueryProps
 * @returns string
 */
export const assignSubQueryConceptColor = (subQueryProps: TSubQueryProps): string => {
  const { index } = subQueryProps || SUB_QUERY_PROPS;

  try {
    return hanaDefault[index % hanaDefault.length];
  } catch (e) {
    console.warn(e);

    return '';
  }
};

/**
 * Returns the assigned domainColor for the domain that the concept belongs to
 * @param uri string
 * @param nodesMap TNodesMap
 * @returns string
 */
export const getDomainColor = (
  questionProps: TQuestionProps,
  subQueryProps?: TSubQueryProps
): string => {
  return subQueryProps?.isSubQuestion
    ? assignSubQueryConceptColor(subQueryProps)
    : getDomainColorForQuery(questionProps);
};
