import { tsParamsInitialState } from '@redux/Studio/QueryCreation/constants/queryCreation.initialState';
import { modalViewType } from './subQuestionsModal.interface';

const genericUser = {
  lastName: '',
  firstName: '',
};

export const questionByIdInitialState = {
  id: null,
  description: '',
  author: genericUser,
  editor: genericUser,
  definition: {},
  createdDate: '',
  lastEditDate: '',
  modelId: null,
  modelName: '',
  runtimeParams: { limit: null, sort: [] },
  solutionId: null,
  tsParams: tsParamsInitialState,
  subQueries: {},
  filters: [],
};

export const subQuestionsModalInitialState = {
  isModalOpen: false,
  modalView: 'search' as modalViewType,
  selectedQuestion: {
    loading: true,
    hasErrors: false,
    payload: questionByIdInitialState,
    graphOptions: {},
    propertiesList: [],
    calcs: {
      list: [],
      loading: true,
      hasErrors: false,
    },
  },
};
