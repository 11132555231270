import {
  IIngestion,
  IIngestionLogDetailsState,
  IngestionEventsState,
  IngestionLogsState,
} from './ingestion.interface';

export const colorPriorities = {
  error: 'rgba(232, 165, 48, 0.05)',
  warning: 'rgba(201, 29, 29, 0.05)',
  none: 'transparent',
};

export const ingestionEventsInitialState: IngestionEventsState = {
  counts: {
    ingestionErrorCount: 0,
    ingestionRunningCount: 0,
    ingestionWarningCount: 0,
    scheduledIngestionCount: 0,
  },
  colorPriorities: 'transparent',
  loading: false,
  hasErrors: false,
};

export const ingestionLogsInitialState: IngestionLogsState = {
  logs: [],
  originalData: [],
  loading: false,
  hasErrors: false,
};

export const ingestionLogDetailsInitialState: IIngestionLogDetailsState = {
  details: null,
  selectedDetails: null,
  isLoading: false,
  hasError: false,

  selectedLogId: -1,
  isMessageExpanded: false,

  stage: null,
  dsName: null,
  tableName: null,
  fileName: null,
  level: '',
};

export const initialIngestionState: IIngestion = {
  ingestionEvents: ingestionEventsInitialState,
  ingestionLogs: ingestionLogsInitialState,
  ingestionLogDetails: ingestionLogDetailsInitialState,
};
