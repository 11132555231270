import { IDataSourceTablesPagination } from '../Datasources.interfaces';

export const dataSourceTablesPaginationInitialState = {
  pageLimit: 10,
  currentPage: 1,
  searchTerm: '',
};

export const studioInitialPath = '/studio/domains';

export const tableColumnsInitialState: ITableColumns = {
  columnInfo: {
    data: [],
    searchCount: 0,
    selectedCount: 0,
    totalCount: 0,

    colCountPerPage: 10,
    page: 1,
    paginationCount: 1,
  },
  columnList: [],
  isLoading: false,
  hasError: false,
  errorCode: -1,
  searchStr: '',
};

interface ITableInfo {
  tablesRaw: any;
  hasError: boolean;
  isLoading: boolean;
  totalTables: number;
  tablesIndexedList: any;
  activeTableName: string | null;
  tablesPagination: IDataSourceTablesPagination;
}

interface IDataSourceTypes {
  typesMap: any;
  typesList: any[];
  hasError: boolean;
  isLoading: boolean;
  csvTypeIds: number[];
}

export interface ITableColumnInfo {
  data: any;
  searchCount: number;
  selectedCount: number;
  totalCount: number;

  // used for pagination
  colCountPerPage: number;
  page: number;
  paginationCount: number;
}

export interface ITableColumns {
  columnInfo: ITableColumnInfo;
  hasError: boolean;
  isLoading: boolean;
  errorCode: number;
  columnList: any;
  searchStr: string;
}

export interface IDataSourceState {
  isLoading: boolean;
  hasLoadingError: boolean;
  filterValues: {
    searchTerm: string;
    typeId: number;
  };
  errorCode: number;
  datasourceList: any;
  filteredList: any;
  isDataSourceModalOpen: boolean;
  selectedDataSourceForModal: any;
  isFetchingDataSourceById: boolean;
  hasErrorFetchingDataSourceById: boolean;
  isDataSourcesEmpty: boolean;
  activeDataSourceSelectedColCount: number;
  activeDataSourceTotalCols: number;
  tableInfo: ITableInfo;
  types: IDataSourceTypes;
  tableColumns: ITableColumns;
  previousPath: string;
}

export const datasourcesInitialState: IDataSourceState = {
  tableInfo: {
    totalTables: 0,
    activeTableName: null,
    isLoading: false,
    hasError: false,
    tablesRaw: [],
    tablesIndexedList: {},
    tablesPagination: dataSourceTablesPaginationInitialState,
  },

  types: {
    isLoading: false,
    hasError: false,
    csvTypeIds: [],
    typesList: [],
    typesMap: {},
  },

  tableColumns: tableColumnsInitialState,

  isLoading: false,
  hasLoadingError: false,
  errorCode: -1,
  datasourceList: null,
  filterValues: {
    searchTerm: '',
    typeId: 0,
  },
  filteredList: [],
  activeDataSourceSelectedColCount: 0,
  activeDataSourceTotalCols: 0,
  isDataSourceModalOpen: false,
  selectedDataSourceForModal: null,
  isFetchingDataSourceById: false,
  hasErrorFetchingDataSourceById: false,

  isDataSourcesEmpty: false,
  previousPath: studioInitialPath,
};
