import { MAPS_INITIAL_STATE } from '../constants/queryCreation.constants';
import { IQuestionDefinition, TMaps, TNodesMap } from '../constants/queryCreation.interface';
import { getDomainColor } from './getDomainColor';
import { getIsSubQuestion } from './getIsSubQuestion';

/**
 * Assigns a color to the question definition, for each concept
 * Also keeps track of the concept instances
 * @param {IQuestionDefinition} definition
 * @param {TNodesMap} nodesMap
 * @returns TMaps
 */
const getQueryMaps = (definition: IQuestionDefinition, nodesMap: TNodesMap): TMaps => {
  try {
    return Reflect.ownKeys(definition).reduce((acc: TMaps, curr, index) => {
      const isSubQuestion = getIsSubQuestion(definition[curr as string]);

      const color = getDomainColor(
        { uri: definition[curr as string]?.uri, nodesMap },
        { isSubQuestion, index }
      );

      return {
        ...acc,
        queryMap: {
          ...acc.queryMap,
          [curr]: {
            ...definition[curr as string],
            color,
          },
        },
        conceptInstanceIdMap: {
          ...acc.conceptInstanceIdMap,
          [definition[curr as string].uri]:
            definition[curr as string].uri in acc.conceptInstanceIdMap
              ? acc.conceptInstanceIdMap[definition[curr as string].uri] + 1
              : 1,
        },
      };
    }, MAPS_INITIAL_STATE);
  } catch (e) {
    console.warn('error building queryMaps', e);
    return {
      ...MAPS_INITIAL_STATE,
      hasError: true,
    };
  }
};

export default getQueryMaps;
