import Features from '../../../../../../../../../Features/feature-flags';

export const CSVTableName = 'File';
export const CSV = 'csv';
export const EXCEL = 'excel';

/**
 * RDBMS Types
 * id 8 => BigQuery
 * id 11 => DataBricks
 * id 1 => Postgres
 * id 2 => Redshift
 * id 3 => Snowflake
 * id 4 => SQL Server
 * id 12 => Oracle
 *
 * Cloud CSV Types
 * id 5 => Amazon S3
 * id 9 => Azure Cloud
 *
 * local CSV type
 * id 6 => LocalCsv
 *
 * ApiDataSourceTypes
 * id 7 => API data source type
 * id 10 => Kobai API data source type
 *
 * Excel
 * id 13 => local xls
 * id 14 => amazon xls
 * id 15 => azure xls
 */
const POSTGRES = 1;
const REDSHIFT = 2;
const SNOWFLAKE = 3;
const SQL_SERVER = 4;
const AMAZON_S3_CSV = 5;
const LOCAL_CSV = 6;
const API = 7;
const GOOGLE_BIG_QUERY = 8;
const AZURE_CLOUD_CSV = 9;
const KOBAI_API = 10;
const DATA_BRICKS = 11;
const ORACLE = 12;
const LOCAL_XLS = 13;
const AMAZON_S3_XLS = 14;
const AZURE_CLOUD_XLS = 15;

export const datasourceTypeIds = {
  POSTGRES,
  REDSHIFT,
  SNOWFLAKE,
  SQL_SERVER,
  AMAZON_S3_CSV,
  LOCAL_CSV,
  API,
  GOOGLE_BIG_QUERY,
  AZURE_CLOUD_CSV,
  KOBAI_API,
  DATA_BRICKS,
  ORACLE,
  LOCAL_XLS,
  AMAZON_S3_XLS,
  AZURE_CLOUD_XLS,
};

/**
 * ApiDataSourceTypes
 * id 7 => API data source type
 * id 10 => Kobai API data source type
 */
export const allAPIDataSourceTypes = [datasourceTypeIds.API, datasourceTypeIds.KOBAI_API];

/**
 * CSV Types
 * id 5 => Amazon S3
 * id 6 => LocalCsv
 * id 9 => Azure Cloud
 */
export const allCSVDataSourceTypes = [
  datasourceTypeIds.AMAZON_S3_CSV,
  datasourceTypeIds.LOCAL_CSV,
  datasourceTypeIds.AZURE_CLOUD_CSV,
];

/**
 * Cloud CSV Types
 * id 5 => Amazon S3
 * id 9 => Azure Cloud
 */
export const allCloudCSVTypes = [
  datasourceTypeIds.AMAZON_S3_CSV,
  datasourceTypeIds.AZURE_CLOUD_CSV,
];

/**
 * Excel Types
 * id 13 => Local XLS
 * id 14 => Amazon S3 XLS
 * id 15 => Azure Cloud XLS
 */
export const allExcelDataSourceTypes = [
  datasourceTypeIds.LOCAL_XLS,
  datasourceTypeIds.AMAZON_S3_XLS,
  datasourceTypeIds.AZURE_CLOUD_XLS,
];

/**
 * RDBMS Types
 * id 8 => BigQuery
 * id 11 => DataBricks
 * id 12 => Oracle
 * id 1 => Postgres
 * id 2 => Redshift
 * id 3 => Snowflake
 * id 4 => SQL Server
 */
// ** Note: **
// allRDBMSTypes are in alphabetical order (by their names)
// They will show in the pill dropdown list according to the order of id's in this rdbmsIdTypes list.
// When adding to this list:
// Checkout the data-svcs/dataSourceType in the network response, to add the id in the appropriate array position
export const allRDBMSTypes = [
  datasourceTypeIds.GOOGLE_BIG_QUERY,
  datasourceTypeIds.DATA_BRICKS,
  datasourceTypeIds.POSTGRES,
  datasourceTypeIds.REDSHIFT,
  datasourceTypeIds.SNOWFLAKE,
  datasourceTypeIds.SQL_SERVER,
  datasourceTypeIds.ORACLE,
];

// if !Features.showBqSource, filter out datasourceTypeIds.GOOGLE_BIG_QUERY
export const DATA_SOURCE_TYPE_PILLS_RDBMS_LIST = Features.showBqSource
  ? allRDBMSTypes
  : allRDBMSTypes.filter((item) => item !== datasourceTypeIds.GOOGLE_BIG_QUERY);

// set default dataSourceTypeId to the first item in the list
export const DEFAULT_RDBMS_SOURCE_TYPE = DATA_SOURCE_TYPE_PILLS_RDBMS_LIST[0];
