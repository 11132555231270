import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEventCounts, IIngestion, ILogDetails } from './ingestion.interface';
import {
  colorPriorities,
  ingestionEventsInitialState,
  ingestionLogsInitialState,
  initialIngestionState,
} from './ingestion.constants';
import { ILogSummaryItem } from '../Ingestion/constants/ingestionLog.interface';

export const ingestionEventsReducer = {
  resetEventsState: (state: IIngestion) => {
    state.ingestionEvents.counts = ingestionEventsInitialState.counts;
    state.ingestionEvents.colorPriorities = ingestionEventsInitialState.colorPriorities;
    state.ingestionEvents.loading = ingestionEventsInitialState.loading;
    state.ingestionEvents.hasErrors = ingestionEventsInitialState.hasErrors;
  },

  setCounts: (state: IIngestion, action: PayloadAction<IEventCounts>) => {
    const counts = action.payload.counts;
    state.ingestionEvents.counts = counts;

    if (counts.ingestionErrorCount > 0) {
      state.ingestionEvents.colorPriorities = colorPriorities.error;
    } else if (counts.ingestionWarningCount > 0) {
      state.ingestionEvents.colorPriorities = colorPriorities.warning;
    }

    state.ingestionEvents.loading = false;
  },

  initFetchEvents: (
    state: IIngestion,
    action: PayloadAction<{ ingestionRunningCount?: number }>
  ) => {
    state.ingestionEvents.loading = true;
    state.ingestionEvents.hasErrors = false;

    if (action.payload.ingestionRunningCount) {
      state.ingestionEvents.counts.ingestionRunningCount = action.payload.ingestionRunningCount;
    }
  },

  errorFetchingEvents: (state: IIngestion) => {
    state.ingestionEvents.loading = false;
    state.ingestionEvents.hasErrors = true;
  },
};

export const ingestionLogsReducer = {
  initFetchLogs: (state: IIngestion) => {
    state.ingestionLogs.loading = true;
    state.ingestionLogs.hasErrors = false;
  },
  successFetchLogs: (
    state: IIngestion,
    action: PayloadAction<{ filteredLogs: ILogSummaryItem[]; allLogs: ILogSummaryItem[] }>
  ) => {
    state.ingestionLogs.loading = false;
    state.ingestionLogs.hasErrors = false;
    state.ingestionLogs.logs = action.payload.filteredLogs;
    state.ingestionLogs.originalData = action.payload.allLogs;
  },
  errorFetchLogs: (state: IIngestion) => {
    state.ingestionLogs.loading = false;
    state.ingestionLogs.hasErrors = true;
  },

  filterLogs: (state: IIngestion, action: PayloadAction<string>) => {
    const filterBy = action.payload;
    const original = state.ingestionLogs.originalData;
    if (!filterBy) {
      state.ingestionLogs.logs = original;
    } else {
      state.ingestionLogs.logs = original.filter((log) => log.status == filterBy);
    }
  },

  filterLogsById: (state: IIngestion, action: PayloadAction<string>) => {
    const searchStr = action.payload.toLowerCase();
    const original = state.ingestionLogs.originalData;
    if (!searchStr) {
      state.ingestionLogs.logs = original;
    } else {
      state.ingestionLogs.logs = original.filter((log) => {
        return (
          log.dataSourceName.toLowerCase().includes(searchStr) ||
          log.ingestionType.toLowerCase().includes(searchStr)
        );
      });
    }
  },
  resetLogsState: (state: IIngestion) => {
    state.ingestionLogs.logs = ingestionLogsInitialState.logs;
    state.ingestionLogs.originalData = ingestionLogsInitialState.originalData;
    state.ingestionLogs.loading = ingestionLogsInitialState.loading;
    state.ingestionLogs.hasErrors = ingestionLogsInitialState.hasErrors;
  },
};

export const ingestionLogDetailsReducer = {
  resetDetails: (state: IIngestion) => {
    state.ingestionLogDetails.details = null;
    state.ingestionLogDetails.selectedDetails = null;
    state.ingestionLogDetails.stage = null;
    state.ingestionLogDetails.dsName = null;
    state.ingestionLogDetails.tableName = null;
    state.ingestionLogDetails.fileName = null;
    state.ingestionLogDetails.level = '';
  },
  initFetchLogDetails: (state: IIngestion) => {
    state.ingestionLogDetails.isLoading = true;
    state.ingestionLogDetails.hasError = false;
  },

  successFetchLogDetails: (state: IIngestion, action: PayloadAction<ILogDetails>) => {
    state.ingestionLogDetails.isLoading = false;
    state.ingestionLogDetails.details = action.payload;
    state.ingestionLogDetails.selectedDetails = action.payload;
  },

  errorFetchLogDetails: (state: IIngestion) => {
    state.ingestionLogDetails.isLoading = false;
    state.ingestionLogDetails.hasError = true;
  },

  setSelectedDetails: (state: IIngestion, action: PayloadAction<ILogDetails>) => {
    state.ingestionLogDetails.selectedDetails = action.payload;
  },
  setMessageIsExpanded: (state: IIngestion, action: PayloadAction<boolean>) => {
    state.ingestionLogDetails.isMessageExpanded = action.payload;
  },
  setStage: (state: IIngestion, action: PayloadAction<string>) => {
    state.ingestionLogDetails.stage = action.payload;
  },
  setDSName: (state: IIngestion, action: PayloadAction<string>) => {
    state.ingestionLogDetails.dsName = action.payload;
  },
  setTableName: (state: IIngestion, action: PayloadAction<string>) => {
    state.ingestionLogDetails.tableName = action.payload;
  },
  setFileName: (state: IIngestion, action: PayloadAction<string>) => {
    state.ingestionLogDetails.fileName = action.payload;
  },
  setLevel: (state: IIngestion, action: PayloadAction<string>) => {
    state.ingestionLogDetails.level = action.payload;
  },
};

export const IngestionSlice = createSlice({
  name: 'ingestion',
  initialState: initialIngestionState,
  reducers: {
    ...ingestionEventsReducer,
    ...ingestionLogsReducer,
    ...ingestionLogDetailsReducer,
  },
});

export const {
  resetEventsState,
  setCounts,
  initFetchEvents,
  errorFetchingEvents,
  initFetchLogs,
  successFetchLogs,
  errorFetchLogs,
  filterLogs,
  filterLogsById,
  resetLogsState,
  setSelectedDetails,
  setMessageIsExpanded,
  setStage,
  setDSName,
  setTableName,
  setFileName,
  setLevel,
  resetDetails,
  initFetchLogDetails,
  successFetchLogDetails,
  errorFetchLogDetails,
} = IngestionSlice.actions;

export default IngestionSlice.reducer;
