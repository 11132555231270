import {
  ICalcs,
  IConcept,
  IQuestionProperty,
  RuntimeCalcAggregates,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';
import {
  CALCULATION_CONCEPT_LABEL,
  CALCULATION_URI,
  PROPERTY_PROPS,
} from '../constants/outputItems';
import { IOutputItem, TPropsMapType } from '../constants/subQuestions.interface';
import { getProp } from './getOutputs';
import { IWindowFunctionItem } from '@components/Studio/QueryCreationTwo/Props/modals/WindowFunctionsModal/constants/interfaces/windowFunctions.interface';
import { NEW_CONCEPT } from '@redux/Studio/QueryCreation/constants/queryCreation.constants';

/**
 * Iterates the aggregates from the calc to be added as an available subQuery output
 * If the aggregate hasn't already been added to the parent question,
 * return the aggregate output
 * @param param0
 * @returns IOutputItem[]
 */
const processAvailableAggregates = ({
  prop,
  aggregates,
  existingPropsMap,
  subQuestionUri,
  propLabel,
  conceptId,
  conceptColor,
  concept,
  calcConceptBaseUri,
}: {
  prop: IQuestionProperty;
  aggregates: RuntimeCalcAggregates[];
  existingPropsMap: TPropsMapType;
  subQuestionUri: string;
  conceptId: string;
  propLabel: string;
  conceptColor: string;
  concept: IConcept;
  calcConceptBaseUri: string;
}): IOutputItem[] => {
  return aggregates.reduce((aggrAcc: IOutputItem[], aggrItem) => {
    const CALC_PROPERTY_URI = `${calcConceptBaseUri}/${propLabel}#${aggrItem.type}_${CALCULATION_CONCEPT_LABEL}`;

    // Check for aggregate props, to see if they've already bee added
    if (CALC_PROPERTY_URI in existingPropsMap) {
      return aggrAcc;
    }

    const aggrProp = {
      ...prop,
      uri: CALC_PROPERTY_URI,
      label: `${propLabel}_${aggrItem.type}`,
    };

    return [
      ...aggrAcc,
      getProp(aggrProp, concept, conceptId, false, subQuestionUri, conceptColor || '', true),
    ];
  }, []);
};

/**
 * Iterates the window functions from the calc to be added as an available subQuery output
 * If the window function hasn't already been added to the parent question,
 * return the window function output
 * @param param0
 * @returns IOutputItem[]
 */
const processAvailableWFs = ({
  prop,
  windowFunctions,
  existingPropsMap,
  subQuestionUri,
  propLabel,
  conceptId,
  conceptColor,
  concept,
  calcConceptBaseUri,
}: {
  prop: IQuestionProperty;
  windowFunctions: IWindowFunctionItem[];
  existingPropsMap: TPropsMapType;
  subQuestionUri: string;
  conceptId: string;
  propLabel: string;
  conceptColor: string;
  concept: IConcept;
  calcConceptBaseUri: string;
}): IOutputItem[] => {
  return windowFunctions.reduce((wfAcc: IOutputItem[], wfItem) => {
    const CALC_PROPERTY_URI = `${calcConceptBaseUri}/${propLabel}#${wfItem.type}_${CALCULATION_CONCEPT_LABEL}`;

    // Check for wf props, to see if they've already bee added
    if (CALC_PROPERTY_URI in existingPropsMap) {
      return wfAcc;
    }

    const wfProp = {
      ...prop,
      uri: CALC_PROPERTY_URI,
      label: `${propLabel}_${wfItem.type}`,
    };

    return [
      ...wfAcc,
      getProp(wfProp, concept, conceptId, false, subQuestionUri, conceptColor || '', true),
    ];
  }, []);
};

/**
 * Iterate the subQuery Calcs to return output items available to be added
 * @param param0
 * @returns IOutputItem[]
 */
export const getAvailableCalcOutputs = ({
  calcs,
  existingPropsMap,
  subQuestionUri,
  baseUri,
}: {
  calcs: ICalcs[];
  existingPropsMap: TPropsMapType;
  subQuestionUri: string;
  baseUri: string;
}): IOutputItem[] => {
  // These values are static and the same for every calc property, for this tenant & ontology
  const CALC_CONCEPT_BASE_URI = `${baseUri}/${CALCULATION_URI}`;
  const CALC_CONCEPT_URI = `${CALC_CONCEPT_BASE_URI}#${CALCULATION_URI}`;

  return calcs.reduce((acc: IOutputItem[], calc) => {
    const CALC_CONCEPT_INSTANCE_URI = `${CALC_CONCEPT_BASE_URI}#${calc.outputKeyName}`; // this is used as the conceptId in helper functions
    const CALC_PROPERTY_URI = `${CALC_CONCEPT_BASE_URI}/${calc.outputKeyName}#${CALCULATION_URI}`;

    // ignore if calc prop output is already added to the query.definition
    if (CALC_PROPERTY_URI in existingPropsMap) {
      return acc;
    }

    const calcProp: IQuestionProperty = {
      ...PROPERTY_PROPS,
      propTypeUri: calc?.type || '',
      label: calc.outputKeyName,
      uri: CALC_PROPERTY_URI,
    };

    const calcConcept: IConcept = {
      ...NEW_CONCEPT,
      label: CALCULATION_CONCEPT_LABEL,
      uri: CALC_CONCEPT_URI,
    };

    const aggregates = calc.aggregates || [];
    const windowFunctions = calc.windowFunctions || [];

    const aggrAndWfCommonProps = {
      prop: calcProp,
      concept: calcConcept,
      existingPropsMap,
      conceptColor: '',
      conceptId: CALC_CONCEPT_INSTANCE_URI,
      subQuestionUri,
      propLabel: calc.outputKeyName,
      calcConceptBaseUri: CALC_CONCEPT_BASE_URI,
    };

    // process aggregates
    const calcAggrs = processAvailableAggregates({
      ...aggrAndWfCommonProps,
      aggregates,
    });

    // process wf's
    const calcWfs = processAvailableWFs({
      ...aggrAndWfCommonProps,
      windowFunctions,
    });

    const outputs =
      aggregates.length > 0 || windowFunctions.length > 0
        ? [...calcAggrs, ...calcWfs]
        : [
            getProp(
              calcProp,
              calcConcept,
              CALC_CONCEPT_INSTANCE_URI,
              false,
              subQuestionUri,
              '',
              false
            ),
          ];

    return [...acc, ...outputs];
  }, []);
};
