import {
  ICalcs,
  IConcept,
  INodesMap,
  IQuestionAggregate,
  IQuestionById,
  IQuestionDefinition,
  IQuestionProperty,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';
import { IOutputItem, TPropsMapType } from '../constants/subQuestions.interface';
import { getProp, getRelation } from './getOutputs';
import { PROPERTY_PROPS, RELATION_PROPS } from '../constants/outputItems';
import {
  SELF_JOIN_LABEL,
  SELF_JOIN_RELATION_NAME,
} from '@redux/Studio/QueryCreation/constants/queryCreation.constants';
import { getAvailableCalcOutputs } from './getAvailableCalcOutputs';
import { IWindowFunctionItem } from '@components/Studio/QueryCreationTwo/Props/modals/WindowFunctionsModal/constants/interfaces/windowFunctions.interface';

type accType = {
  props: IOutputItem[];
  existingPropsMap: TPropsMapType;
};

type conceptsWithAggregatesType = Record<string, boolean>;

type TExistingPropsMap = {
  props: IOutputItem[];
  existingPropsMap: Record<string, boolean>;
};

type TConceptsWithAggrs = Record<string, boolean>;

type TAvailableRelationsMap = Record<string, string>;

const getProperties = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string
) => {
  return concept.properties.reduce(
    (acc: accType, prop) => {
      return {
        props: [
          ...acc.props,
          getProp(
            prop,
            concept,
            conceptId,
            isExisting,
            subQuestionUri,
            concept.color || '',
            false // default isAggregate to false, since it doesn't matter. Aggregate outputs are added with propTypeUri = number
          ),
        ],
        existingPropsMap: {
          ...acc.existingPropsMap,
          [prop.uri]: true,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );
};

const getAvailableRelations = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  nodesMap: INodesMap,
  availableRelationsMap: TAvailableRelationsMap,
  subQuestionUri: string,
  conceptsWithAggregates: conceptsWithAggregatesType
) => {
  return Object.keys(nodesMap?.[concept.uri]?.relations || {}).reduce(
    (relAcc: { props: IOutputItem[]; existingPropsMap: TAvailableRelationsMap }, relName) => {
      const rel = nodesMap[concept.uri].relations[relName];

      // Prevent two use cases:
      // 1. Prevent adding duplicate relations.
      //    This use case happens when there are multiple concept instances of the same concept
      //    with a concept connecting to them via the same relation name
      //    example:
      //    conceptA1 -> conceptB1
      //    conceptA1 -> conceptB2 via the same relation type
      // 2. Prevent adding a relation for a concept with properties that have aggregates
      if (
        (!relAcc.existingPropsMap[rel.relationTypeUri] ||
          relAcc.existingPropsMap[rel.relationTypeUri] !== rel.uri) &&
        !(conceptId in conceptsWithAggregates)
      ) {
        return {
          props: [
            ...relAcc.props,
            getRelation(rel, concept, conceptId, isExisting, subQuestionUri, concept.color || ''),
          ],
          existingPropsMap: {
            ...relAcc.existingPropsMap,
            [rel.relationTypeUri]: rel.uri,
          },
        };
      } else {
        return relAcc;
      }
    },
    { props: [], existingPropsMap: availableRelationsMap }
  );
};

const getRelationsToOriginalQuestion = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string
) => {
  return Object.keys(concept.relations).reduce(
    (acc: { props: IOutputItem[]; existingPropsMap: TPropsMapType }, relName) => {
      const isSelfJoin = relName === SELF_JOIN_RELATION_NAME;
      const rel = {
        ...concept.relations[relName],
        selfJoin: isSelfJoin,
      };

      if (isSelfJoin) {
        return rel.relationInstances.reduce(
          (instanceAcc: { props: IOutputItem[]; existingPropsMap: TPropsMapType }, item) => {
            return {
              props: [
                ...instanceAcc.props,
                getRelation(
                  {
                    ...rel,
                    relationTypeUri: item.relationTypeUri,
                    uri: conceptId,
                  },
                  concept,
                  conceptId,
                  isExisting,
                  subQuestionUri,
                  concept.color || ''
                ),
              ],
              existingPropsMap: {
                ...instanceAcc.existingPropsMap,
              },
            };
          },
          acc
        );
      }

      return {
        props: [
          ...acc.props,
          getRelation(rel, concept, conceptId, isExisting, subQuestionUri, concept.color || ''),
        ],
        existingPropsMap: {
          ...acc.existingPropsMap,
          [rel.relationTypeUri]: true,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );
};

/**
 * Iterates the aggregates from the property to be added as an available subQuery output
 * If the aggregate hasn't already been added to the parent question,
 * return the aggregate output
 * @param param0
 * @returns IOutputItem[]
 */
const processAvailableAggregates = ({
  propReset,
  aggregates,
  existingPropsMap,
  subQuestionUri,
  propUri,
  propLabel,
  conceptId,
  conceptColor,
  concept,
}: {
  propReset: IQuestionProperty;
  aggregates: IQuestionAggregate[];
  existingPropsMap: TPropsMapType;
  subQuestionUri: string;
  conceptId: string;
  propUri: string;
  propLabel: string;
  conceptColor: string;
  concept: IConcept;
}): IOutputItem[] => {
  return aggregates.reduce((aggrAcc: IOutputItem[], aggrItem) => {
    const uri = `${propUri}_${aggrItem.type}`;

    // Check for aggregate props, to see if they've already bee added
    if (uri in existingPropsMap) {
      return aggrAcc;
    }
    const aggrProp = {
      ...propReset,
      uri,
      label: `${propLabel}_${aggrItem.type}`,
      propTypeUri: 'http://www.w3.org/2001/XMLSchema#number', //reset to a number type
    };

    return [
      ...aggrAcc,
      getProp(aggrProp, concept, conceptId, false, subQuestionUri, conceptColor || '', true),
    ];
  }, []);
};

/**
 * Iterates the window functions from the property to be added as an available subQuery output
 * If the window function hasn't already been added to the parent question,
 * return the window function output
 * @param param0
 * @returns IOutputItem[]
 */
const processAvailableWFs = ({
  propReset,
  windowFunctions,
  existingPropsMap,
  subQuestionUri,
  propUri,
  propLabel,
  conceptId,
  conceptColor,
  concept,
}: {
  propReset: IQuestionProperty;
  windowFunctions: IWindowFunctionItem[];
  existingPropsMap: TPropsMapType;
  subQuestionUri: string;
  conceptId: string;
  propUri: string;
  propLabel: string;
  conceptColor: string;
  concept: IConcept;
}): IOutputItem[] => {
  return windowFunctions.reduce((aggrAcc: IOutputItem[], wfItem) => {
    const uri = `${propUri}_${wfItem.type}`;

    // Check for aggregate props, to see if they've already bee added
    if (uri in existingPropsMap) {
      return aggrAcc;
    }

    const wfProp = {
      ...propReset,
      uri,
      label: `${propLabel}_${wfItem.type}`,
      propTypeUri: 'http://www.w3.org/2001/XMLSchema#number', //reset to a number type
    };

    return [
      ...aggrAcc,
      getProp(wfProp, concept, conceptId, false, subQuestionUri, conceptColor || '', true),
    ];
  }, []);
};

/**
 *  Returns the props that have been added from the sub-q to the parent question
 * @param param0
 * @returns TExistingPropsMap
 */
const getExistingProps = ({
  subQuestionDefinition,
  subQuestionUri,
  query,
}: {
  subQuestionDefinition: IQuestionDefinition;
  subQuestionUri: string;
  query: IQuestionById;
}): TExistingPropsMap => {
  const existingProps = Object.keys(subQuestionDefinition).reduce(
    (acc: accType, conceptURI) => {
      const concept = subQuestionDefinition[conceptURI];

      const props = getProperties(concept, conceptURI, true, subQuestionUri);
      const relations = getRelationsToOriginalQuestion(concept, conceptURI, true, subQuestionUri);

      // Self joins to the original question
      const selfJoins = Object.keys(query.definition).reduce(
        (acc: IOutputItem[], conceptInstance) => {
          // check to see if the instance is already there as a selfJoin in the relations
          const indexOfExisting = relations.props.findIndex(
            (item: IOutputItem) =>
              item.relationTypeUri === conceptInstance && item.label === SELF_JOIN_LABEL
          );

          if (concept.uri === query.definition[conceptInstance].uri && indexOfExisting === -1) {
            return [
              ...acc,
              {
                ...PROPERTY_PROPS,
                ...RELATION_PROPS,
                label: subQuestionDefinition[conceptURI].label,
                relationTypeUri: conceptInstance, //conceptURI,
                uri: subQuestionUri,
                isExisting: false,
                selfJoin: true,
                isRelation: true,
                conceptId: conceptURI, //the concept in the sub-question
                conceptName:
                  subQuestionDefinition[conceptURI].outputCategory ||
                  subQuestionDefinition[conceptURI].label,
                conceptUri: subQuestionDefinition[conceptURI].uri,
                subQuestionUri: subQuestionUri,
                color: query.definition[conceptInstance].color || '',
                isAggregate: false, // false, since it's a selfJoin relation
              },
            ];
          }
          return acc;
        },
        []
      );

      return {
        props: [...acc.props, ...props.props, ...relations.props, ...selfJoins],
        existingPropsMap: {
          ...acc.existingPropsMap,
          ...props.existingPropsMap,
          ...relations.existingPropsMap,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );

  return existingProps;
};

/**
 * Iterates the reference definition and returns a map of concept instances
 * that have properties w/aggregates set
 * @param referenceDefinition IQuestionDefinition
 * @returns Record<string, boolean>
 */
const getConceptsWithPropertiesThatHaveAggregates = (
  referenceDefinition: IQuestionDefinition = {}
): TConceptsWithAggrs => {
  return Object.keys(referenceDefinition).reduce(
    (acc: conceptsWithAggregatesType, conceptId: string) => {
      return {
        ...acc,
        ...referenceDefinition[conceptId].properties.reduce(
          (acc: conceptsWithAggregatesType, prop: IQuestionProperty) => ({
            ...acc,
            ...(prop.aggregates.length > 0 ? { [conceptId]: true } : {}),
          }),
          {}
        ),
      };
    },
    {}
  );
};

/**
 * Iterate the referenceDefinition and get all of props that are available from the original question AND
 * All relations possible
 * @param param0
 * @returns IOutputItem[]
 */
const getAllPropsAndRelationsAvailable = ({
  referenceDefinition,
  existingProps,
  subQuestionUri,
  nodesMap,
  availableRelationsMap,
  conceptsWithAggregates,
  calcOutputs,
}: {
  referenceDefinition: IQuestionDefinition;
  existingProps: TExistingPropsMap;
  subQuestionUri: string;
  calcOutputs: IOutputItem[];
  conceptsWithAggregates: TConceptsWithAggrs;
  nodesMap: INodesMap;
  availableRelationsMap: TAvailableRelationsMap;
}): IOutputItem[] => {
  return Object.keys(referenceDefinition).reduce(
    (acc: IOutputItem[], curr) => {
      const concept = referenceDefinition[curr];

      const filteredProps = concept.properties.reduce((propAcc: IOutputItem[], prop) => {
        // Check for aggregate props, to see if they've already bee added

        // prevent adding props that are already added OR are hidden
        if (prop.uri in existingProps.existingPropsMap || prop.hidden) {
          return propAcc;
        } else {
          // Reset prop settings to a new property, so that the user can update output settings
          // Keep the label, propTypeLabel & propTypeUri as the original, in case the user renamed the prop
          const propReset: IQuestionProperty = {
            ...prop,

            // override (reset) property settings w/the PROPERTY_PROPS
            ...PROPERTY_PROPS,

            // keep these properties from the existing property settings
            propTypeUri: prop.propTypeUri,
            label: prop.label, // this is the prop used when the user renames a property
            propTypeLabel: prop.propTypeLabel, // this is the original name of the property
          };

          // Display aggregates as a individual property output to be added
          const aggrOutputs = processAvailableAggregates({
            propReset,
            aggregates: prop.aggregates,
            existingPropsMap: existingProps.existingPropsMap,
            subQuestionUri,
            propLabel: prop.label,
            propUri: prop.uri,
            conceptId: curr,
            conceptColor: concept.color || '',
            concept,
          });

          // Display window functions as a individual property output to be added
          const wfOutputs = processAvailableWFs({
            propReset,
            windowFunctions: prop.windowFunctions,
            existingPropsMap: existingProps.existingPropsMap,
            subQuestionUri,
            propLabel: prop.label,
            propUri: prop.uri,
            conceptId: curr,
            conceptColor: concept.color || '',
            concept,
          });

          const propertiesToAdd =
            // only return aggrOutputs & wfOutputs if the original subQuery prop has aggregates added
            prop.aggregates.length > 0 || prop.windowFunctions.length > 0
              ? [...aggrOutputs, ...wfOutputs]
              : [getProp(propReset, concept, curr, false, subQuestionUri, concept.color || '')];

          return [...propAcc, ...propertiesToAdd];
        }
      }, []);

      // Get all relations possible:
      // 1. Prevent dupes w/availableRelationsMap &
      // 2. Prevent relations for concepts that have props w/aggregates (w/conceptsWithAggregates)
      const availableRelations = getAvailableRelations(
        concept,
        curr,
        false,
        nodesMap,
        availableRelationsMap,
        subQuestionUri,
        conceptsWithAggregates
      );

      availableRelationsMap = availableRelations.existingPropsMap;

      return [...acc, ...filteredProps, ...availableRelations.props];
    },
    [...existingProps.props, ...calcOutputs]
  );
};

/**
 * Iterates the definition, reference sub-question for the available outputs (properties & aggregates)
 * & nodesMap to get all of the available relations for a concept
 * @param subQuestionDefinition
 * @param referenceDefinition
 * @returns
 */
export const getSelectedSubQueryOutputsAndRelations = (
  subQuestionDefinition: IQuestionDefinition = {},
  referenceDefinition: IQuestionDefinition = {},
  nodesMap: INodesMap = {},
  subQuestionUri: string,
  query: IQuestionById,
  subQueryCalcs: ICalcs[],
  baseUri: string
) => {
  // These are the props that have been added to the sub-q
  const existingProps = getExistingProps({ subQuestionDefinition, subQuestionUri, query });

  // available calc outputs
  const calcOutputs = getAvailableCalcOutputs({
    calcs: subQueryCalcs,
    existingPropsMap: existingProps.existingPropsMap,
    subQuestionUri,
    baseUri,
  });

  let availableRelationsMap = {};
  // map of concept instances that have properties w/aggregates set
  const conceptsWithAggregates = getConceptsWithPropertiesThatHaveAggregates(referenceDefinition);

  // These are the props that are available from the original question AND
  // All relations possible
  const allProps = getAllPropsAndRelationsAvailable({
    nodesMap,
    subQuestionUri,
    referenceDefinition,
    availableRelationsMap,
    calcOutputs,
    existingProps,
    conceptsWithAggregates,
  });

  return allProps.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLowerCase()));
};
