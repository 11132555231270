import { BaseErrorState } from '@redux/root-redux-types';
import { IMappingState } from './mapping.interface';

const ERROR_STATE: BaseErrorState[] = [];

export const mappingFunctionDef = {
  type: null,
  column: [],
};

export const emptySelectedProperty = {
  label: '',
  mappings: [],
  uri: 'string',
  propTypeUri: '',
  propTypeLabel: null,
};

export const relationLookupsDefaults = {
  typeId: 0,
  function: mappingFunctionDef,
  columnName: null,
  tableName: null,
  datasourceId: null,
};

export const mappingDefBase = {
  id: null,
  label: '',
  nullOverride: {},
  columnName: null,
  conceptUri: '',
  conceptTypeUri: '',
  dataSetId: null,
  filters: [],
  function: mappingFunctionDef,
  idColumnName: null,
  idFunction: mappingFunctionDef,
  mappingPropTypeId: 0, //default to direct mapping
  mappingIdTypeId: 0,
  mappingOverlapIdTypeId: 0,
  overlapIdFunction: mappingFunctionDef,
  overlapColumnName: null,
  overlapTableName: null,
  overlapDatasourceId: null,
  propertyUri: '',
  propertyTypeUri: '',
  timeseries: {
    conceptIdColumn: null,
    dataSetId: null,
    idColumn: null,
    timestampColumn: null,
    timestampFormat: null,
    valueColumn: null,
  },
  timestampProps: {
    timestampFormat: null,
    timeZone: null,
    alignTo: null,
  },
  relationLookups: relationLookupsDefaults,
  modalLabels: {
    propName: '',
    conceptName: '',
  },
  scenarioIds: [],
};

export const mappingTabsDefaults = {
  overlapChecked: false,
  overlapSelectedDataset: null,
};

export const mappingInitialState: IMappingState = {
  canEdit: false,
  loading: false,
  hasErrors: false,
  errors: ERROR_STATE,
  selectedProperty: emptySelectedProperty,
  showMappingModal: false,
  selectedMapping: null,
  conceptIdOverlaps: [],
  dataSets: {
    selectedDatasetId: null,
    selectedDataset: null,
    dataSetColumns: {
      loading: false,
      hasError: false,
      errors: ERROR_STATE,
      list: [],
      options: [],
    },
    timeSeriesDataSetColumns: {
      loading: false,
      hasError: false,
      errors: ERROR_STATE,
      list: [],
      options: [],
    },
  },
  lookups: {
    loading: false,
    error: null,
    // relationLookups in the general tab
    relations: {
      dataSetColumns: {
        loading: false,
        error: ERROR_STATE,
        list: [],
        options: [],
      },
      overlapChecked: false,
      overlapSelectedDataset: null,
    },
    // relationLookups in the conceptId tab
    identifier: {
      overlapChecked: false,
      overlapSelectedDataset: null,
      overlapSelectedDatasetOptions: [],
    },
  },
};
