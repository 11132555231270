import { hanaContrastColor } from '../contrastColors';

export const hanaDefault: string[] = [
  '#255863',
  '#34638D',
  '#3CA9B0',
  '#3288BD',
  '#83C5DA',
  '#A05095',
  '#D45087',
  '#EC6CA9',
  '#3EC192',
  '#83C58D',
  '#8CE06E',
  '#B8DE29',
  '#C4BE81',
  '#F0E366',
  '#F9C741',
  hanaContrastColor,
];

export const hanaDefaultMixed: string[] = [
  hanaDefault[0],
  hanaDefault[2],
  hanaDefault[4],
  hanaDefault[6],
  hanaDefault[8],
  hanaDefault[10],
  hanaDefault[12],
  hanaDefault[14],
  hanaDefault[1],
  hanaDefault[3],
  hanaDefault[5],
  hanaDefault[7],
  hanaDefault[9],
  hanaDefault[11],
  hanaDefault[13],
];
